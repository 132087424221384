<template>
  <div class="successIndex">
    <div class="bar clearfix">
      <van-icon name="arrow-left" @click="onClickLeft" class="fl" @click.stop />
      <van-icon class="fl" />
      <div class="fl">慕兰康</div>
    </div>
    <img :src="url" />
    <div class="succes_text">{{ successText }}</div>
    <div class="success_btn" @click="onSuccess">确定</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: "",
      successText: "",
      url: require("../assets/image/chenggong.png")
    };
  },
  methods: {
    onSuccess() {
      
      this.$router.push({
        path: "/Integral/Index",
        query: {
          type: "success"
        }
      });
      
      // console.log("确定");
    },
    onClickLeft() {
      this.$router.go(-1);
    }
  },
  mounted() {
    this.successText = "购买成功！";
  }
};
</script>
<style scoped lang="scss">
.successIndex {
  width: 375px;
  height: 100vh;
  background: #fff;
  .bar {
    background-image: url("../assets/image/Bar.png");
    background-size: 100%;
    background-repeat: no-repeat;
    height: 64px;
    width: 375px;
    background-size: 100%;
    text-align: center;
    box-sizing: border-box;
    div {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 64px;
      margin-left: 88px;
    }
  }
  .van-icon {
    width: 15px;
    height: 15px;
    color: #fff;
    margin-right: 18px;
    margin-top: 20px;
    box-sizing: border-box;
  }
  .van-nav-bar__title.van-ellipsis {
    color: #fff;
    margin: 0 auto;
    font-size: 18px;
    line-height: 64px;
  }
  img {
    width: 53px;
    height: 53px;
    margin: 115px 161px 29px;
  }
  .succes_text {
    width: 375px;
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
  }
  .success_btn {
    width: 320px;
    height: 45px;
    background-image: url("../assets/image/btnbac.png");
    background-size: 100%;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 45px;
    text-align: center;
    margin-left: 27px;
    margin-top: 186px;
  }
}
</style>
