import Vue from "vue";
import Vuex from "vuex";
import InvoiceStore from './invoice'; //引入刚才的invoice.js 

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Result: InvoiceStore
  }
})