/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";
import Cookies from "js-cookie";
import Success from "../views/Success.vue";


Vue.use(VueRouter);

const routes = [{
        path: '/',
        redirect: '/index'
    },
    {
        path: "/home",
        name: "home",
        component: () => {
            return import("../views/Home.vue");
        },
        // meta:{
        //     title: '首页'
        //   },
        children: [{
                path: "/index",
                name: "Index",
                component: () => {
                    return import("../views/Index.vue");
                },
                meta: {
                    title: '首页'
                }
            },
            {
                path: "/personal/index",
                name: "personal",
                component: () => {
                    return import("../views/Personal/Index.vue");
                },
                meta: {
                    title: '慕兰康'
                }
            },
            {
                path: "/integral/index",
                name: "integralIndex",
                component: () => {
                    return import("../views/Integral/Index.vue");
                },
                meta: {
                    title: '商城'
                }
            },
        ]
    },
    {
        path: "/success",
        name: "success",
        component: Success
    },
    {
        path: "/login",
        name: "Login",
        component: () => {
            return import("../views/Login.vue");
        },
        meta: {
            title: '绑定手机号'
        }
    },
    {
        path: "/information",
        name: "Information",
        component: () => {
            return import("../views/Information.vue");
        },
        meta: {
            title: '完善信息'
        }
    },
    // 详情
    {
        path: "/detail/index",
        name: "Detail",
        component: () => {
            return import("../views/Detail/Index.vue");
        },
        meta: {
            title: '商品详情'
        }
        
    },
    // 购买
    {
        path: "/buy/index",
        name: "Buy",
        component: () => {
            return import("../views/Buy/Index.vue");
        },
        meta: {
            title: '购买'
        }
    },
    // 兑换
    {
        path: "/exchange/index",
        name: "Exchange",
        component: () => {
            return import("../views/Exchange/Index.vue");
        },
        meta: {
            title: '兑换'
        }
    },
    // 兑换结果
    {
        path: "/result/index",
        name: "Result",
        component: () => {
            return import("../views/Result/Index.vue");
        },
        meta: {
            title: '兑换结果'
        }
    },
    // 订单页面(经销商)
    {
        path: "/order/index",
        name: "Order",
        component: () => {
            return import("../views/Order/Index.vue");
        },
        meta: {
            title: '慕兰康'
        }
    },
    // 订单页面(电商)
    {
        path: "/customerOrder/index",
        name: "CustomerOrder",
        component: () => {
            return import("../views/CustomerOrder/Index.vue");
        },
        meta: {
            title: '慕兰康'
        }
    },
    // 下级订单页面
    {
        path: "/levelOrder/index",
        name: "LevelOrder",
        component: () => {
            return import("../views/LevelOrder/Index.vue");
        },
        meta: {
            title: '慕兰康'
        }
    },
    // 我的佣金
    {
        path: "/commission/index",
        name: "Commission",
        component: () => {
            return import("../views/Commission/Index.vue");
        },
        meta: {
            title: '慕兰康'
        }
    },
    // 提现
    {
        path: "/money/index",
        name: "Money",
        component: () => {
            return import("../views/Money/Index.vue");
        },
        meta: {
            title: '提现'
        }
    },
    // 我的合作商
    {
        path: "/dealers/index",
        name: "Dealers",
        component: () => {
            return import("../views/Dealers/Index.vue");
        },
        meta: {
            title: '我的合作商'
        }
    },
    {
        path: "/dealers/detail",
        name: "Dealersdetail",
        component: () => {
            return import("../views/Dealers/Detail.vue");
        },
        meta: {
            title: '下级经销商'
        }
    },
    // 分发明细
    {
        path: "/distribution/index",
        name: "Distribution",
        component: () => {
            return import("../views/Distribution/Index.vue");
        },
        meta: {
            title: '我的经销商'
        }
    },
    // 联系我们
    {
        path: "/call/index",
        name: "Call",
        component: () => {
            return import("../views/Call/Index.vue");
        },
        meta: {
            title: '慕兰康'
        }
    },
    // 佣金规则
    {
        path: "/rules/index",
        name: "Rules",
        component: () => {
            return import("../views/Rules/Index.vue");
        },
        meta: {
            title: '规则'
        }
    },
    // 海报
    {
        path: "/posters/index",
        name: "Posters",
        component: () => {
            return import("../views/Posters/Index.vue");
        },
        meta: {
            title: '慕兰康'
        }
    },
    // 申诉
    {
        path: "/complaint/index",
        name: "Complaint",
        component: () => {
            return import("../views/Complaint/Index.vue");
        },
        meta: {
            title: '申诉'
        }
    },
    // 发票
    {
        path: "/invoice/index",
        name: "Invoice",
        component: () => {
            return import("../views/Invoice/Index.vue");
        },
        meta: {
            title: '发票',
            keepAlive: true
        }
    },

    // 申请经销商
    {
        path: "/ApplyAgent/index",
        name: "applyAgent",
        component: () => {
            return import("../views/ApplyAgent/Index.vue");
        },
        meta: {
            title: '申请经销商',
            keepAlive: true
        }
    },

    // 可开发票订单
    {
        path: "/invoiceOrder/index",
        name: "InvoiceOrder",
        component: () => {
            return import("../views/InvoiceOrder/Index.vue");
        },
        meta: {
            title: '发票'
        }
    },
    // demo
    {
        path: "/demo",
        name: "Demo",
        component: () => {
            return import("../views/Demo.vue");
        },
        meta: {
            title: 'Demo'
        }
    },

    // 用户地址
    {
        path: "/Address/index",
        name: "Address",
        component: () => {
            return import("../views/Address/Index.vue");
        },
        meta: {
            title: '地址'
        }
    },
];

const router = new VueRouter({
    routes
});



// 路由守护，进行登录判断
router.beforeEach((to, from, next) => {
    // var userInfo = sessionStorage.getItem("user"); //获取浏览器缓存的用户信息
    var token = Cookies.get("token"); //获取浏览器缓存的token信息
    if (token) {
        // 如果浏览器缓存了用户信息直接进入默认首页
        next();
    } else {
        // window.location.href = "http://hbn.kunchuangtech.net/index.php/Mobile";
        next();
        // if (to.path === "/login") {
        //     //如果是登录页面路径，就直接next()
        //     next();
        // } else {
        //     //不然就跳转到登录；
        //     next({ path: '/login' })
        // }
    }
});

export default router;
