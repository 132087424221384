/*
 * @Author: your name
 * @Date: 2021-06-22 17:41:02
 * @LastEditTime: 2022-02-13 14:18:25
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vue_mlk\src\plugins\axios.js
 */
import axios from "axios";
import Cookies from "js-cookie";

// 请求拦截
axios.interceptors.request.use(
  config => {
    // let string = window.location.href;
    // let str = "#";
    // let str_before = string.split(str)[1];
    // if (str_before !== "/login") {
    //     // 如果不是login带上token
    //     // Cookies.set('token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJrdW5jaHVhbmd0ZWNoIiwiaWF0IjoxNjQzMDk5NTc3LCJleHAiOjE2NDU2OTE1NzcsInVpZCI6MzU1LCJ1c2VybmFtZSI6Ilx1MzAwMiJ9.9oE1Q54VO4a9ttqWTksLcjqazbiTVMKf0Pne5-WReGU')
    //     Cookies.set('token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJrdW5jaHVhbmd0ZWNoIiwiaWF0IjoxNjQ0MTUwNDgxLCJleHAiOjE2NDY3NDI0ODEsInVpZCI6NzUsInVzZXJuYW1lIjoiXHU2ZDBiXHU2ZDBiIn0.kUGkjrN5W3tI7lPKq3cwTuCQsKDsjpcAjKfumJCG_SI')
    //     var token = Cookies.get("token"); //获取浏览器缓存的token信息
    //     config.headers.common["token"] = token;
    //     // axios.defaults.headers.common['token'] = token;
    // }
    if (config.url.toLowerCase() !== `login`) {
      // 如果不是login带上token
        // let token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJrdW5jaHVhbmd0ZWNoIiwiaWF0IjoxNjQ0MTUwNDgxLCJleHAiOjE2NDY3NDI0ODEsInVpZCI6NzUsInVzZXJuYW1lIjoiXHU2ZDBiXHU2ZDBiIn0.kUGkjrN5W3tI7lPKq3cwTuCQsKDsjpcAjKfumJCG_SI"
      var token = Cookies.get("token"); //获取浏览器缓存的token信息
      config.headers.common["token"] = token;
      axios.defaults.headers.common["token"] = token;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// 响应拦截
axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default axios;
