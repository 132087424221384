/* eslint-disable */

import axios from "../plugins/axios";
let api = "http://new.tongyuanxinshiji.com/index.php/Mobile"
import Qs from 'qs'

export default {
    // 语法：

    // GET(params) {
    //   return axios.request({
    //     url: "",
    //     method: "get",
    //     params
    //   });
    // },
    // POST(data) {
    //   return axios.request({
    //     url: "",
    //     method: "post",
    //     data,
    //   });
    // },

    //获取验证码
    getPhoneCode(data) {
        return axios.request({
            url: `${api}/user/send_sms_reg_code`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //短信验证
    getMsg(data) {
        return axios.request({
            url: `${api}/user/sms_code_verify`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //完善信息提交
    upUser(data) {
        return axios.request({
            url: `${api}/user/update_userinfo`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //首页-轮播
    getBanner(data) {
        return axios.request({
            url: `${api}/MobileIndex/index_banner`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },

        });
    },
    //首页-分销信息
    getInfo(data) {
        return axios.request({
            url: `${api}/MobileIndex/hx_info`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },

        });
    },
    //首页-热销推荐
    getBestList(data) {
        return axios.request({
            url: `${api}/MobileIndex/best_sellers`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },

        });
    },
    //积分商城-商品分类
    getClassify(data) {
        return axios.request({
            url: `${api}/shop/goods_category`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
        });
    },
    //积分商城-商品列表
    getShopList(data) {
        return axios.request({
            url: `${api}/shop/goods_list`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //积分商城-商品详情
    getDetail(data) {
        return axios.request({
            url: `${api}/shop/goods_info`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //积分商城-立即兑换
    buyNow(data) {
        return axios.request({
            url: `${api}/shop/buy`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //积分商城-申请兑换
    getPhone(data) {
        return axios.request({
            url: `${api}/shop/apply_point`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //积分商城-兑换提交
    pay(data) {
        return axios.request({
            url: `${api}/shop/pay`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //积分商城-订单状态
    getOrderStatus(data) {
        return axios.request({
            url: `${api}/shop/order_shipping_status`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //积分商城-订单列表
    getOrderList(data) {
        return axios.request({
            url: `${api}/shop/orderlist`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //积分商城-提醒发货
    remind(data) {
        return axios.request({
            url: `${api}/shop/remind_shipping`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //积分商城-确认收货
    confirmReceipt(data) {
        return axios.request({
            url: `${api}/shop/confirm_receipt`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //积分商城-申诉
    complain(data) {
        return axios.request({
            url: `${api}/shop/complain`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //个人中心-个人信息
    userinfo(data) {
        return axios.request({
            url: `${api}/User/userinfo`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
        });
    },
    //个人中心-积分规则
    getRule_jf(data) {
        return axios.request({
            url: `${api}/User/rule_jf`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
        });
    },
    //个人中心-佣金规则
    getRule_yj(data) {
        return axios.request({
            url: `${api}/User/rule_yj`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
        });
    },
    //个人中心-我的佣金
    getMoney(data) {
        return axios.request({
            url: `${api}/User/distribut_money`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
        });
    },
    //个人中心-收入明细
    getIncomeList(data) {
        return axios.request({
            url: `${api}/User/income_list`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //个人中心-提现记录
    getTxList(data) {
        return axios.request({
            url: `${api}/User/tx_list`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //个人中心-申请提现
    txApply(data) {
        return axios.request({
            url: `${api}/User/tx_apply`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //个人中心-搜索时间数组
    getDateList(data) {
        return axios.request({
            url: `${api}/User/date_plugin`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //个人中心-提现时间
    txTime(data) {
        return axios.request({
            url: `${api}/User/tx_time`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
        });
    },
    //个人中心-提现最低额度
    txMin(data) {
        return axios.request({
            url: `${api}/User/edu_tx_min`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
        });
    },
    //个人中心-联系我们
    aboutUs(data) {
        return axios.request({
            url: `${api}/User/about_us`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
        });
    },
    //个人中心-分发明细
    getffList(data) {
        return axios.request({
            url: `${api}/User/ff_list`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //个人中心-经销商等级列表
    getLevelList(data) {
        return axios.request({
            url: `${api}/User/level_hy`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //个人中心-我的经销商
    getDealersList(data) {
        return axios.request({
            url: `${api}/User/my_subordinates`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //个人中心-积分分发
    Give(data) {
        return axios.request({
            url: `${api}/User/ff_give`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //个人中心-下级订单
    getSuborder(data) {
        return axios.request({
            url: `${api}/shop/suborder`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //个人中心-下级订单
    delivery(data) {
        return axios.request({
            url: `${api}/shop/delivery`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //个人中心-推广二维码
    getCode(data) {
        return axios.request({
            url: `${api}/User/erweima`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded',
                // 'Access-Control-Allow-Origin':'*'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //个人中心-省
    province(data) {
        return axios.request({
            url: `${api}/shop/province`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
        });
    },
    //个人中心-市
    city(data) {
        return axios.request({
            url: `${api}/shop/city`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
        });
    },
    //个人中心-区
    dist(data) {
        return axios.request({
            url: `${api}/shop/dist`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
        });
    },
    //个人中心-申请加盟商
    applyAgent(data) {
        console.log(data,"data,,,,,")
        return axios.request({
            url: `${api}/shop/applyAgent`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded',
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //个人中心-开发票
    saveInvoice(data) {
        console.log(data,"data,,,,,")
        return axios.request({
            url: `${api}/shop/invoice`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded',
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    //个人中心-开发票所选订单
    getInvoiceOrder(data) {
        return axios.request({
            url: `${api}/user/zd_wc_order`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
        });
    },
    //个人中心-海报
    getHb(data) {
        return axios.request({
            url: `${api}/user/hb_create`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
        });
    },
    //个人中心-申诉说明
    instructions(data) {
        return axios.request({
            url: `${api}/user/rule_ss`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
        });
    },
    //信息完善和手机绑定
    Perfect(data) {
        return axios.request({
            url: `${api}/User/validated_sj_info`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                // 'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    // 下级
    subordinates(data) {
        return axios.request({
            url: `${api}/user/my_subordinates_lower`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
    
    //上传图片 
    uploadImg(data) {
        return axios.request({
            url: `${api}/User/img_up`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
        });
    },

    //支付
    getPay(data) {
        return axios.request({
        url: `${api}/Payment/getCode`,
        method: "post",
        data,
        //  新增content-type头部属性
        heads: {
            'content-type': 'application/x-www-form-urlencoded'
        },
        transformRequest: [function (data) {
            // 对 data 进行任意转换处理
            return Qs.stringify(data)
        }],
        });
    }, 

    //个人中心-地址
    handleGetUserAddress(data) {
        return axios.request({
            url: `${api}/user/user_address_list`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
        });
    },

    //设置默认地址
    setDefaultAddress(data) {
        return axios.request({
            url: `${api}/user/set_user_default_address`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },

    //个人中心-地址
    handleGetUserDefaultAddress(data) {
        return axios.request({
            url: `${api}/user/get_user_default_address`,
            method: "post",
            data,
            //  新增content-type头部属性
            heads: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                return Qs.stringify(data)
            }],
        });
    },
};
