export default {
  state: {
    resultOrder: [],
    invoiceNum: ""
  },
  mutations: {
    Count(state, result) {
      state.resultOrder = result;
    },
    Num(state, result){
      state.invoiceNum = result;
    }
  }
  //   actions: {
  //       Acount (context) {
  //         context.commit('Count ')
  //     }
  // }
};
